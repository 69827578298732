<template>
    <main>
        <div class="gallery">
            <div class="gallery_frame">
                <div class="gallery_container" id="container_1">
                        <img src="@/assets/1.jpg" class="gallery_img">
                        <img src="@/assets/2.jpg" class="gallery_img">
                        <img src="@/assets/3.jpeg" class="gallery_img">
                        <img src="@/assets/4.jpeg" class="gallery_img">
                </div>

                <div class="gallery_container" id="container_2">
                        <img src="@/assets/5.jpeg" class="gallery_img">
                        <img src="@/assets/6.jpg" class="gallery_img">
                        <img src="@/assets/7.jpg" class="gallery_img">
                        <img src="@/assets/8.jpg" class="gallery_img">
                        <img src="@/assets/9.jpg" class="gallery_img">
                </div>

                <div class="gallery_container" id="container_3">
                        <img src="@/assets/10.jpg" class="gallery_img">
                        <img src="@/assets/11.jpg" class="gallery_img">
                        <img src="@/assets/12.jpg" class="gallery_img">
                        <img src="@/assets/13.jpg" class="gallery_img">
                </div>

                <div class="gallery_container" id="container_4">
                        <img src="@/assets/14.jpg" class="gallery_img">
                        <img src="@/assets/15.jpg" class="gallery_img">
                        <img src="@/assets/16.jpg" class="gallery_img">
                        <img src="@/assets/17.jpg" class="gallery_img">
                        <img src="@/assets/18.jpg" class="gallery_img">
                
                </div>
            </div>
        </div>

        <div class="lightbox" id="lightbox">
            <a class="prev" id="prev">&#10094;</a>
            <div class="'lightbox_image">
                <img class="lightbox_content" id="lightbox_img">
            </div>
            <a class="next" id="next">&#10095;</a>
            <span class="close">&times;</span>
        </div>
        
    </main>
</template>

<script>
    export default{
        name:'GalleryComponent'
    }

    //------------------------------------------------------------------ Gallery Random Layout-----------------------------------------

    document.addEventListener("DOMContentLoaded", () => {
        const galleryContainers = document.querySelectorAll('.gallery_container');
        let images = [];

        // Collect all image elements from all containers
        galleryContainers.forEach(container => {
            images = images.concat(Array.from(container.children));
        });

        // Fisher-Yates Shuffle Algorithm
        function shuffle(array) {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]];
            }
            return array;
        }

        // Shuffle the images array
        const shuffledImages = shuffle(images);

        // Remove all images from their current containers
        images.forEach(img => img.parentElement.removeChild(img));

        // Reassign shuffled images back to the containers
        let galleryIndex = 0;
        shuffledImages.forEach(img => {
            galleryContainers[galleryIndex].appendChild(img);
            galleryIndex = (galleryIndex + 1) % galleryContainers.length;
        });
    });

    //------------------------------------------------------------------ Gallery Slider -----------------------------------------

    document.addEventListener('DOMContentLoaded', () => {
        console.log('DOM fully loaded and parsed');
        const galleryItems = document.querySelectorAll('.gallery_img');
        const lightbox = document.getElementById('lightbox');
        const lightboxImg = document.getElementById('lightbox_img');
        const closeBtn = document.querySelector('.close');
        const prevBtn = document.getElementById('prev');
        const nextBtn = document.getElementById('next');
        
        console.log('Gallery Items:', galleryItems);
        console.log('Lightbox:', lightbox);
        console.log('Lightbox Image:', lightboxImg);
        console.log('Close Button:', closeBtn);
        console.log('Previous Button:', prevBtn);
        console.log('Next Button:', nextBtn);

        let currentIndex = 0;

        const openLightbox = (index) => {
            lightbox.style.display = 'grid';
            lightboxImg.src = galleryItems[index].src;
            currentIndex = index;
        };

        const closeLightbox = () => {
            lightbox.style.display = 'none';
        };

        const showPrev = () => {
            currentIndex = (currentIndex === 0) ? galleryItems.length - 1 : currentIndex - 1;
            lightboxImg.src = galleryItems[currentIndex].src;
        };

        const showNext = () => {
            currentIndex = (currentIndex === galleryItems.length - 1) ? 0 : currentIndex + 1;
            lightboxImg.src = galleryItems[currentIndex].src;
        };

        galleryItems.forEach((item, index) => {
            item.addEventListener('click', () => openLightbox(index));
        });

        if (closeBtn) {
            closeBtn.addEventListener('click', closeLightbox);
        } else {
            console.error('Close button not found');
        }

        if (prevBtn) {
            prevBtn.addEventListener('click', showPrev);
        } else {
            console.error('Previous button not found');
        }

        if (nextBtn) {
            nextBtn.addEventListener('click', showNext);
        } else {
            console.error('Next button not found');
        }

        if (lightbox) {
            lightbox.addEventListener('click', (e) => {
                if (e.target === lightbox) {
                    closeLightbox();
                }
            });
        } else {
            console.error('Lightbox not found');
        }
    });
</script>

<style scoped>
*{
    margin: 0;
    padding: 0;
}

body, html {
    margin: 0;
    padding: 0;
    width: 100%;
}

main{
    background-color: #5E5E5E;
}

.gallery{
    margin-left: 180px;
    margin-right: 180px;  
}

.gallery .gallery_frame{
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    grid-auto-flow: row;
    gap: 10px;
    width: 100%;
}

@media (max-width:600px) {
    .gallery{
        margin-left: 20px;
        margin-right: 20px;
        gap: 5px;
    }
    .gallery .gallery_frame{
        grid-template-columns: calc(50% - 2.5px) calc(50% - 2.5px);
    }
    .column-1{
        align-self: start;
    }
    .column-2{
        align-self: start;
    }
}

.gallery_container{
    display: grid;
    gap: 10px;
    overflow: hidden;
}

@media (max-width:600px) {
    .gallery_container{
        display: flex;
        flex-direction: column;
    }
}

.gallery .gallery_frame .gallery_container img{
    height: 100%;
    width: 100%;
    display: block;
    object-fit: cover;
    align-items: center;
    transition: transform 0.3s ease, filter 0.3s ease;  
}

@media (max-width:600px) {
    .gallery .gallery_frame .gallery_container img{
        height:auto;
    }
}

.gallery_img:hover {
    cursor: pointer;
    transform: scale(1.1);
    filter: brightness(75%) grayscale(75%);
}

.lightbox {
    display: none;
    position: fixed;
    z-index: 9999;
    padding-top: 60px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.7);
    justify-content: center;
    align-items: center;
}

@media (max-width:600px) {
    .lightbox{
        position: fixed;
        padding-top: 5px;
        width:100vw;
        height: 100vh;
        grid-template-columns: 15% 70% 15%;
    }
}

.lightbox_image {
    width: auto;
    height: 70%;
    overflow: hidden;
    object-fit: cover;
}

.lightbox_content{
    object-fit: cover;   
}

@media (max-width:600px) {
    .lightbox_image{
        position: relative;
    }

    .lightbox_content{
        position: relative;
        top: 0;
        margin: 2px;
        width: 100%;
        height: auto;
    }
}

.close {
    position: absolute;
    top: 20px;
    left: 35px;
    color: white;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
    cursor: pointer;
}

@media (max-width:600px) {
    .close{
        left: 90vw;
        font-size: 30px;
    }
}

.close:hover,
.close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}

.prev,
.next {
    cursor: pointer;
    position: absolute;
    width: auto;
    color: white;
    font-weight: bold;
    font-size: 20px;
    transition: 0.6s ease;
    user-select: none;
}

@media (max-width:600px){
    .prev,
    .next{
        position: relative;
        transform: translateX(50%);
    }
}

.prev:hover,
.next:hover {
    background-color: rgba(0, 0, 0, 0.8);
}
</style>